import dateFormat from 'dateformat';

export const formatSQLDate = date => {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
}

export const formatSQLDateShort = date => {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year % 100}`;
}

export const parseSQLDate = date => {
    if (!date) return null;
    if (!date.match('^\\d{2}\\.\\d{2}\\.\\d{4}$')) return null;
    const [day, month, year] = date.split('.');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export const formatDateTime = date => {
    return dateFormat(date, "dd.mm.yyyy HH:MM:ss")
}