<template>
    <v-container fill-height fluid>
        <v-row>
            <v-spacer/>
            <v-col cols="12" md="6">
                <v-form @submit.prevent="login">
                    <v-container>
                        <v-row>
                            <v-col class="d-flex justify-center">
                                <img :src="logo" width="132" height="44"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                    <v-text-field v-model="id" label="Номер карты" outlined></v-text-field>
                                    <v-text-field v-model="pass" label="Пароль" type="password" outlined></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex">
                                <v-btn color="accent" @click="goRegister">Регистрация</v-btn>
                                <v-spacer/>
                                <v-btn color="primary" type="submit" :disabled="!enabled">Войти</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-container>
</template>

<script>
import logo from '@/assets/logo.svg';

export default {
    name: 'LoginForm',
    data() {
        return {
            id: '',
            pass: '',
            logo
        }
    },
    methods: {
        login() {
            this.$emit('login', { id: this.id, pass: this.pass });
        },
        goRegister() {
            this.$emit('goRegister');
        }
    },
    computed: {
        enabled: {
            get() {
                return (this.id?.length === 13) && (this.pass?.length > 0);
            }
        }
    },
}
</script>
  