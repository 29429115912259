<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <div><small>Номер карты</small></div>
                <div>{{ account.id }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div><small>Фамилия</small></div>
                <div>{{ account.owner_family_name }}</div>
            </v-col>
            <v-col cols="4">
                <div><small>Имя</small></div>
                <div>{{ account.owner_first_name }}</div>
            </v-col>
            <v-col cols="4">
                <div><small>Отчество</small></div>
                <div>{{ account.owner_third_name }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div><small>Дата рождения</small></div>
                <div>{{ account.owner_birthday }}</div>
            </v-col>
            <v-col cols="4">
                <div><small>Пол</small></div>
                <div>{{ sexName }}</div>
            </v-col>
            <v-col cols="4">
                <div><small>Город</small></div>
                <div>{{ account.owner_city }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div><small>Телефон</small></div>
                <div>{{ account.owner_phone }}</div>
            </v-col>
            <v-col cols="4">
                <div><small>Email</small></div>
                <div>{{ account.owner_email }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-switch v-model="ownerSms" :true-value="1" :false-value="0">
                    <template v-slot:label>
                        Я согласен(согласна) получать СМС рассылку торговой сети Гипермаркетов &laquo;ЛИНИЯ&raquo;
                    </template>
                </v-switch>
                <div><small>Настоящим я даю разрешение сети Гипермаркетов &laquo;ЛИНИЯ&raquo; получать, собирать, хранить
                    и использовать мои персональные данные: фамилию, имя, отчество, дату рождения, почтовый адрес,
                    контактный телефон в целях бонусной программы. Согласие может в любой момент быть мной отозвано
                    путем направления письменного уведомления. С условиями программы ознакомлен(а).
                </small></div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-switch v-model="active" :true-value="1" :false-value="0" label="Карта активна"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'AccountForm',
    props: {
        account: Object
    },
    data() {
        return {
            ownerSms: 1,
            active: 1
        }
    },
    computed: {
        sexName: {
            get() { 
                switch (this.account?.owner_sex) {
                    case 1 : return "Мужской"; 
                    case 2 : return "Женский"; 
                }
                return "";
            }
        }
    },
    watch: {
        account(value) {
            this.ownerSms = value?.owner_sms;
            this.active = value?.active;
        },
        ownerSms(value) {
            if (this.account.owner_sms !== value)
                this.$emit("change", { owner_sms: value });
        },
        active(value) {
            if (this.account.active !== value)
                this.$emit("change", { active: value });
        }
    }
}
</script>