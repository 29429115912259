<template>
    <v-form @submit.prevent="register">
        <v-container>
            <v-row>
                <v-col class="d-flex justify-center">
                    <img :src="logo" width="132" height="44"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-card-title>Карта</v-card-title>
                        <v-card-text class="pb-0">
                            <v-text-field v-model="account.id" label="Номер карты" hint="Номер карты на обратной стороне" maxlength="13" outlined/>
                            <v-text-field v-model="account.wtmpass" label="Код с чека" hint="Код можно найти на чеке" maxlength="10" outlined/>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card outlined>
                        <v-card-title>Пароль</v-card-title>
                        <v-card-text class="pb-0">
                            <v-text-field v-model="account.wpass" label="Пароль" type="password" hint="Задайте пароль для личного кабинета" outlined/>
                            <v-text-field v-model="account.wpass_confirm" label="Подтверждение пароля" type="password" hint="Подтвердите пароль" outlined/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-card-title>Персональные данные</v-card-title>
                        <v-card-text class="pb-0">
                            <v-container class="pa-0">
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field v-model="account.owner_family_name" label="Фамилия" maxlength="64" outlined/>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="account.owner_first_name" label="Имя" maxlength="64" outlined/>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="account.owner_third_name" label="Отчество" maxlength="64" outlined/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <date-picker v-model="account.owner_birthday" label="Дата рождения"/>
                                    </v-col>
                                    <v-col cols="8">
                                        <div><small>Пол</small></div>
                                        <v-radio-group v-model="account.owner_sex" row>
                                            <v-radio label="Мужской" :value="1"></v-radio>
                                            <v-radio label="Женский" :value="2"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="account.owner_city" label="Город" maxlength="32" outlined/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="account.owner_phone" label="Телефон" type="phone" v-mask="'+7-###-###-##-##'" outlined/>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="account.owner_email" label="Email" type="email" maxlength="64" outlined/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-switch v-model="account.owner_sms" :true-value="1" :false-value="0">
                        <template v-slot:label>
                            Я согласен(согласна) получать СМС рассылку торговой сети Гипермаркетов &laquo;ЛИНИЯ&raquo;
                        </template>
                    </v-switch>
                    <div><small>Настоящим я даю разрешение АО «Корпорация «ГРИНН» получать, собирать, хранить и использовать
                        мои персональные данные: фамилию, имя, отчество, дату рождения, почтовый адрес,
                        контактный телефон в целях бонусной программы. 
                        Согласие может в любой момент быть мной отозвано путем направления письменного уведомления.
                        Настоящим подтверждаю, что я ознакомлен и согласен с <a href="https://grinn-corp.ru/wp-content/uploads/2023/09/Polozhenie-o-zashhite-personalnyh-dannyh-v-AO-Korporaciya-GRINN.pdf">Положением о защите персональных данных</a> в АО «Корпорация «ГРИНН».
                    </small></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex">
                    <v-spacer/>
                    <v-btn color="primary" type="submit" :disabled="!enabled">Зарегистрироваться</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import DatePicker from './DatePicker.vue';
import logo from '@/assets/logo.svg';

export default {
    name: 'RegisterForm',
    data() {
        return {
            account: {
                owner_sms: 1,
            },
            logo
        }
    },
    methods: {
        register() {
            this.$emit('register', this.account);
        }
    },
    computed: {
        enabled: {
            get() {
                return this.account.id &&
                    this.account.id.length === 13 &&
                    this.account.wtmpass &&
                    this.account.wpass &&
                    this.account.wpass_confirm &&
                    this.account.owner_family_name &&
                    this.account.owner_first_name &&
                    this.account.owner_birthday &&
                    this.account.owner_sex;
            }
        }
    },
    components: {
        'date-picker': DatePicker
    }
}
</script>