import api from './api';
import store from '@/store';

const apimsgsvc = async ({options, successMessages, whenSuccess, afterSuccessMessages, errorMessages, whenError, afterErrorMessages}) => {
    store.commit('page/setLoading', true);
    try {
        const response = await api(options);

        if (whenSuccess) whenSuccess(response);

        if (successMessages) {
            store.commit('page/setSuccessMessages', successMessages);
            setTimeout(() => {
                store.commit('page/setSuccessMessages', []);
                if (afterSuccessMessages) afterSuccessMessages(response);
            }, 2000);
        }
    }
    catch (e) {
        if (whenError) whenError(e);

        let messages = [];
        if (errorMessages)
            messages = errorMessages;
        if (e.response && e.response.data && e.response.data.error)
            messages = [ e.response.data.error ];
        store.commit('page/setErrorMessages', messages);
        setTimeout(() => {
            store.commit('page/setErrorMessages', []);
            if (afterErrorMessages) afterErrorMessages(e);
        }, 2000);
    }
    store.commit('page/setLoading', false);
}

export default apimsgsvc;