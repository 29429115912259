<template>
    <div>
        <v-radio-group v-model="transactionsType" row mandatory v-show="hasBonuses && hasRubles">
            <v-radio v-if="hasBonuses" label="Бонусы" value="1"/>
            <v-radio v-if="hasRubles" label="Рубли" value="2"/>
        </v-radio-group>
        <v-data-table :headers="headers" :items="filteredTransactions" :footerProps="footerProps" no-data-text="Операции отсутствуют">
            <!-- eslint-disable-next-line -->
            <template v-slot:item.name="{item}">
                <span>{{ item.name }} 
                    <span v-if="item.qrlink">
                        <v-btn icon :href="item.qrlink" target="_blank">
                            <v-icon>mdi-receipt-text-outline</v-icon>
                        </v-btn>
                    </span>
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { formatDateTime } from '@/services/dateformatter';

export default {
    name: 'TransactionList',
    data() {
        return {
            headers: [
                { text: 'Дата, время', align: 'start', value: 'ts', sortable: false },
                { text: 'Операция', align: 'start', value: 'name', sortable: false  },
                { text: 'Сумма', align: 'end', value: 'amount', sortable: false  },
                { text: 'Баланс', align: 'end', value: 'balance', sortable: false  }
            ],
            footerProps: {
                showFirstLastPage: true
            },
            transactionsType: 0
        }
    },
    computed: {
        filteredTransactions: {
            get() {
                let filtered = [];
                if (this.transactionsType === '1')
                    filtered = this.transactions.filter(t => t.amount_bns != 0).map(t => ({ts: t.ts, name: t.name, amount: t.amount_bns, balance: t.balance_bns, qrlink: t.qrlink}))
                if (this.transactionsType === '2')
                    filtered = this.transactions.filter(t => t.amount != 0).map(t => ({ts: t.ts, name: t.name, amount: t.amount, balance: t.balance, qrlink: t.qrlink}))
                
                filtered = filtered.map(t => ({...t, ts: formatDateTime(t.ts)}));
                return filtered;
            }
        },
        hasBonuses: {
            get() {
                return [1, 2, 3].includes(this.accountType);
            }
        },
        hasRubles: {
            get() {
                return [0, 1].includes(this.accountType);
            }
        }
    },
    props: {
        transactions: Array,
        accountType: Number
    }
}
</script>