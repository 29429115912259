<template>
    <div class="d-flex justify-space-between">
        <div>
            {{ account.id }}
        </div>
        <div>
            <v-icon size="32"  color="blue">mdi-credit-card</v-icon>
            {{ account.type_name }}
        </div>
        <div>
            {{ account.owner_family_name }}
            {{ account.owner_first_name }}
            {{ account.owner_third_name }}
        </div>
        <div>
            <v-chip class="me-2" color="green" outlined v-if="account.balance_bns > 0">Бонусных баллов: {{ account.balance_bns  }} Б</v-chip>
            <v-chip class="me-2" color="blue" outlined v-if="account.balance > 0">Рублей: {{ account.balance  }} &#8381;</v-chip>
            <v-chip class="me-2" color="blue" outlined v-if="account.chip_name">{{account.chip_name}}: {{ account.chip_balance }}</v-chip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountPanel',
    props: {
        account: Object
    }
}
</script>