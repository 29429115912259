import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import MainView from '@/views/MainView.vue';
import AccountView from '@/views/AccountView.vue';
import LogoutView from '@/views/LogoutView.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'LoginView',
		component: LoginView,
		meta: { fullScreen: true, public: true }
	},
	{
		path: '/register',
		name: 'RegisterView',
		component: RegisterView,
		meta: { fullScreen: true, public: true }
	},
    {
        path: '/',
        name: 'MainView',
        component: MainView,
    },
    {
        path: '/account',
        name: 'AccountView',
        component: AccountView,
    },
	{
		path: '/logout',
		name: 'LogoutView',
		component: LogoutView
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	store.commit('page/setFullScreen', to.meta.fullScreen || false);
	if (to.meta.public || store.state.auth.token) {
		next();
	}
	else {
		next({ name: 'LoginView' });
	}
})

export default router;