<template>
    <login-form @login="login" @goRegister="goRegister"/>
</template>

<script>
import apimsgsvc from '@/services/apimsgsvc.js';
import LoginForm from '@/components/LoginForm.vue';

export default {
    name: 'LoginView',
    methods: {
        async login(account) {
            const recaptcha = this.$recaptchaInstance;
            recaptcha.showBadge();
            const recaptchaToken = await recaptcha.execute('login');
            apimsgsvc({
                options: { path: '/user/login', method: 'post', data: { ...account, recaptcha: recaptchaToken } },
                whenSuccess: response => {
                    recaptcha.hideBadge();
                    this.$store.commit('auth/setToken', response.token);
                    this.$router.push('/');
                },
                whenError: () => this.password = '',
                errorMessages: ['Ошибка авторизации']
            });
        },
        goRegister() {
            this.$router.push({ name: 'RegisterView' });
        }
    },
    components: {
        'login-form': LoginForm,
    }
}
</script>
  