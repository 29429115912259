<template>
    <p>Выход из системы</p>
</template>

<script>
export default {
    name: 'LogoutView',
    methods: {
        logout() {
            this.$store.commit('auth/setToken', null);
            this.$router.push({ name: 'LoginView' });
        }
    },
    mounted() {
        this.logout();
    }
}
</script>