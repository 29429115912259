import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import vmask from 'v-mask';
import { VueReCaptcha } from 'vue-recaptcha-v3';

const recaptchaSiteKey = '6LeRxfgkAAAAABZ4vxf_ywgwL_wJF_pKyNFlwm0Y';

Vue.config.productionTip = false;

Vue.use(vmask);
Vue.use(VueReCaptcha, { siteKey: recaptchaSiteKey, loaderOptions: { autoHideBadge: true } })

store.commit('auth/loadToken');

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App),
}).$mount('#app')
