import axios from 'axios';
import store from '@/store';
import router from '@/router';

const apiurl = (route) => ( `https://lk.grinn-corp.ru/api/v2${route}` ); 

const api = async (options) => {
    const request = {
        url: apiurl(options.path),
        method: options.method,
        params: options.params,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
        },
        data: options.data
    };
    
    if (store.state.auth.token)
        request.headers['Authorization'] = 'Bearer ' + store.state.auth.token;
    
    try {
        const response = await axios(request);
        return response.data;
    }
    catch (e) {
        if (e.response.status === 401) {
            store.commit('auth/setToken', null);
            router.push({ name: 'LoginView' }).catch(()=>{});
        }
        throw(e);
    }
}

export default api;