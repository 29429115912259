<template>
    <div>
        <account-panel :account="account"></account-panel>
        <transaction-list :transactions="transactions" :accountType="this.account?.type"/>
    </div>
</template>

<script>
import apimsgsvc from '@/services/apimsgsvc.js';
import AccountPanel from '@/components/AccountPanel.vue';
import TransactionList from '../components/TransactionList.vue';

export default {
    name: 'MainView',
    data() {
        return {
            account: {},
            transactions: []
        }
    },
    methods: {
        loadAccount() {
            apimsgsvc({
                options: { path: '/user/account', method: 'get'},
                whenSuccess: response => this.account = response,
                errorMessages: ['Ошибка загрузки']
            });
        },
        loadTransactions() {
            apimsgsvc({
                options: { path: '/user/transactions', method: 'get'},
                whenSuccess: response => this.transactions = response,
                errorMessages: ['Ошибка загрузки']
            });
        }
    },
    mounted() {
        this.loadAccount();
        this.loadTransactions();
    },
    components: {
        'account-panel': AccountPanel,
        'transaction-list': TransactionList
    }
}
</script>
  