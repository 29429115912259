import Vue from 'vue';
import Vuex from 'vuex';
import jwt_decode from 'jwt-decode';

Vue.use(Vuex);

const TOKEN_KEY = 'token';

const pageModule = {
	state: () => ({
		fullScreen: false,
		loading: false,
		successMessages: [],
		errorMessages: [],
	}),
	mutations: {
		setFullScreen: (state, fullScreen) => state.fullScreen = fullScreen,
		setLoading: (state, loading) => state.loading = loading,
		setSuccessMessages: (state, successMessages) => state.successMessages = successMessages,
		setErrorMessages: (state, errorMessages) => state.errorMessages = errorMessages,
	},
	namespaced: true,
};

const authModule = {
	state: () => ({
		token: null,
	}),
	getters: {
		user: state => {
			if (!state.token) return null;
			const jwt = jwt_decode(state.token);
			return jwt.user;
		},
	},
	mutations: {
		setToken: (state, token) => {
			state.token = token;
			if (token)
				localStorage.setItem(TOKEN_KEY, token);
			else 
				localStorage.removeItem(TOKEN_KEY);
		},
		loadToken: state => state.token = localStorage.getItem(TOKEN_KEY),
	},
	namespaced: true,
}

export default new Vuex.Store({
	modules: {
		page: pageModule,
		auth: authModule,
	},
	strict: true
});