<template>
	<register-form @register="register"/>
</template>

<script>
import apimsgsvc from '@/services/apimsgsvc.js';
import RegisterForm from '@/components/RegisterForm.vue';

export default {
    name: 'RegisterView',
    methods: {
        async register(account) {
            const recaptcha = this.$recaptchaInstance;
            const recaptchaToken = await recaptcha.execute('register');
            apimsgsvc({
                options: { path: '/user/register', method: 'post', data: { ...account, recaptcha: recaptchaToken }},
                errorMessages: ['Ошибка регистрации'],
                successMessages: ['Регистрация завершена успешно'],
                afterSuccessMessages: () => this.$router.push({ name: 'LoginView' }),
            });
        },
    },
    components: {
        'register-form': RegisterForm,
    }
}
</script>