<template>
<v-app>
	<v-app-bar v-if="!fullScreen" app dense>
		<v-tabs>
			<v-tab to="/">Транзакции</v-tab>
			<v-tab to="/account">Личные данные</v-tab>
			<v-spacer/>
			<v-tab to="logout">Выйти</v-tab>
		</v-tabs>
	</v-app-bar>

	<v-main  class="ma-6">
		<router-view></router-view>
	</v-main>

	<div class="messages">
		<v-alert v-for="success in successMessages" :key="success" type="success">{{ success }}</v-alert>
		<v-alert v-for="error in errorMessages" :key="error" type="error">{{ error }}</v-alert>
	</div>
	<v-progress-circular indeterminate class="loading" v-if="loading"/>
</v-app>
</template>

<script>
export default {
	name: 'App',
	computed: {
		pageTitle() {
			return this.$store.state.page.title;
		},
		fullScreen() {
			return this.$store.state.page.fullScreen;
		},
		user() {
			return this.$store.getters['auth/user'];
		},
		loading() {
			return this.$store.state.page.loading;
		},
		successMessages() {
			return this.$store.state.page.successMessages;
		},
		errorMessages() {
			return this.$store.state.page.errorMessages;
		},
	}
}
</script>

<style scoped>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.loading {
    position: fixed;
    right: 10px;
    bottom: 10px
}
.messages {
    position: fixed;
    bottom: 10px;
    left: 50%;
    margin-left: -100px;
}

</style>
