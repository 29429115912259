<template>
	<account-form :account="account" @change="onChange"/>
</template>

<script>
import apimsgsvc from '@/services/apimsgsvc.js';
import AccountForm from '@/components/AccountForm.vue';

export default {
    name: 'AccountView',
    data() {
        return {
            account: {},
        }
    },
    methods: {
        loadAccount() {
            apimsgsvc({
                options: { path: '/user/account', method: 'get'},
                whenSuccess: response => this.account = response,
                errorMessages: ['Ошибка загрузки']
            });
        },
        onChange(value) {
            apimsgsvc({
                options: { path: '/user/account', method: 'put', data: value },
                whenSuccess: response => this.account = response,
                errorMessages: ['Ошибка загрузки']
            });
        }
    },
    mounted() {
        this.loadAccount();
    },
    components: {
        'account-form': AccountForm,
    }
}
</script>