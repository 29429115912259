<template>
    <div>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateFormatted" :label="label" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" :active-picker.sync="activePicker" :max="(new Date()).toISOString().substring(0, 10)" min="1920-01-01" @change="save"></v-date-picker>
        </v-menu>
  </div>
</template>

<script>
import { formatSQLDate, parseSQLDate } from '@/services/dateformatter.js';

export default {
    name: 'DatePicker',
    data() {
        return {
            activePicker: null,
            menu: false,
        }
    },
    props: {
        value: String,
        label: String
    },
    methods: {
        save (date) {
            this.$refs.menu.save(date)
        },
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        dateFormatted: {
            get() {
                return formatSQLDate(this.date);
            },
            set(value) {
                this.date = parseSQLDate(value);
            }
        }
    },
    watch: {
        menu (value) {
            value && setTimeout(() => (this.activePicker = 'YEAR'));
        },
    }
}
</script>